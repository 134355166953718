
.pointsel {
    outline: 1px solid #f00;
    margin: 1% 5%;
    height: 150px;
    position: relative;
}
.pointsel::after {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    top: 66%;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    border: 1px solid #999;
    background-color: #fff;
    transform: translate(-50%, -50%);
}

.pointsel::before {
    width: 6px;
    height: 6px;
    border: 1px solid #999;
    transform: translate(-50%, 0) rotate(45deg);
    border-right: none;
    border-bottom: none;
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
}

.pointsel-left-top,
.pointsel-right-top,
.pointsel-left-bottom,
.pointsel-right-bottom {
    position: absolute;
    width: 50%;
}

.pointsel-left-top,
.pointsel-left-bottom {
    left: 0;
}

.pointsel-right-top,
.pointsel-right-bottom {
    right: 0;
}

.pointsel-left-top,
.pointsel-right-top {
    top: 0;
    height: 66%;
}

.pointsel-left-bottom,
.pointsel-right-bottom {
    top: 66%;
    height: 34%;
}

.pointsel-left-bottom,
.pointsel-right-bottom {
    border-top: 1px solid #999;
}

.pointsel-left-bottom,
.pointsel-left-top {
    border-right: 1px solid #999;
}

.pointsel-lefttext,
.pointsel-righttext {
    font-size: 12px;
    color: #999;
    position: absolute;
    top: 66%;
}
.pointsel-lefttext {
    left: 0;
    transform: translate(-100%, -50%);
}
.pointsel-righttext {
    right: 0;
    transform: translate(100%, -50%);
}
.mt5 {
    margin-top: 5px;
}

.cpoint {
    position: absolute;
    left: 50%;
    top: 66%;
    border: 1px solid #fff;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-left: -4px;
    margin-top: -4px;
}

.cpoint-left {
    background-color: #55D7AB;
}
.cpoint-right {
    background-color: #DDE934;
}