
.form-layer {
    position: fixed;
    z-index: 5000;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.form-content {
    width: 80%;
    max-width: 1200px;
    height: 80%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    padding: 20px;
}

.unit-item {
    margin-top: 30px;
}
.unit-form {
    margin-top: 15px;
}
.unit-form-button {
    margin-top: 12px;
}
.unit-title {
    font-weight: 700;
}
.add-unit-btn {
    margin-top: 30px;
}


