

.changdi {
    width: 300px;
    height: 300px;
    position: relative;
    background-image: url(https://aicoach.karryai.tech/static/images/img28.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    outline: 1px solid red;
    display: inline-block;
    overflow: hidden;
}
.changdi-jienei {
    position: absolute;
    width: 60%;
    left: 18.5%;
    height: 90%;
    top: 1.5%;
    /* outline: 1px solid blue; */
}

.changdi-scale.changdi {
    background-size: 75% 75%;
    background-position: center bottom;
}
.changdi-scale .changdi-jienei {
    width: 45%;
    left: 26.5%;
    height: 68%;
    top: 26%;
}

.changdi-serve {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 54%;
    width: 100%;
    /* background-color: orange; */
    /* outline: 1px solid orange; */
}


.changdi-point {
    position: absolute;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    border: 2px solid #DDE934;
    border-radius: 3px;
    transform: translate(-50%, 50%);
    left: 0;
    bottom: 0;
    background-color: #DDE934;
}
.changdi-backhand {
    border: 2px solid #53DBAD;
    background-color: #53DBAD;
}

.changdi-serve1,
.changdi-jump1 {
    border: 2px solid #55D7AB;
    background-color: #55D7AB;
}
.changdi-serve2,
.changdi-jump2 {
    border: 2px solid #DDE934;
    background-color: #DDE934;
}
.changdi-jump3 {
    border: 2px solid #FFCD45;
    background-color: #FFCD45;
}

.changdi-out {
    background-color: transparent;
}


