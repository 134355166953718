
.time-item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border: 1px solid #1677ff;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 4px;
    cursor: pointer;
}
.time-item:hover {
    background-color: #1677ff;
    color: #fff;
}
.time-item-select {
    background-color: #1677ff;
    color: #fff;
};

.time-list-item {
    padding-bottom: 10px;
}

.changguan-title {
    font-size: 20px;
    margin-top: 15px;
}

.list-item {
    padding-bottom: 10px;
}